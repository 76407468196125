<template>
  <th
    scope="col"
    class="tw-py-3 tw-px-3 tw-text-left tw-text-sm tw-font-semibold tw-text-neutral-900"
  >
    <slot />
  </th>
</template>
<script setup lang="ts"></script>
<style scoped></style>
